import React from 'react';
import {Link as RouterLink} from 'react-router-dom';

/**
 * Site header/nav component
 */
const Header = ({menuDoc}) => {
    // if (menuDoc) {
    return (
        <header className="site-header">
            <div className="install-me-nav">
                <p>
                    <a className="btn btn-primary" href="#" id={"install-button"}>Installez-moi :)</a>
                </p>
            </div>
            <RouterLink to="/">
                <div className="logo"><img src="/images/logo-marandon-dark.svg" alt="logo"/>
                </div>
            </RouterLink>
            <nav className="d-none d-lg-inline-block">
                <ul className="nav">
                    {/*{menuDoc.data.menu_links.map(menuLink => (*/}
                    {/*    <MenuLink*/}
                    {/*        menuLink={menuLink}*/}
                    {/*        key={menuLink.link.id}*/}
                    {/*    />*/}
                    {/*))}*/}
                    <li className="nav-item">
                        <a className="nav-link contact" href="/contact/">Contact</a>
                    </li>
                    <li className="nav-item">
                    </li>
                </ul>
            </nav>
        </header>
    );
    // }
    // return null;
};

export default Header;
