import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import HomepageSingleProject from './HomepageSingleProject';

/**
 * Homepage projects component
 */
const HomepageProjects = ({projects, tagline}) => (
    <section className="homepage-projects container-fluid">
      <div className="container">
        <div className="row">
          <div className="col-12 projects-header">
            <h3 className="h2">Derniers projets</h3>
            {/*<RouterLink*/}
            {/*    className="float-right"*/}
            {/*    to="/projets/">Voir plus de projets*/}
            {/*</RouterLink>*/}
          </div>
          <div className="col-md-6 projects-list">
            <HomepageSingleProject className="big" project={projects[0]}/>
            <HomepageSingleProject className="small" project={projects[1]}/>
          </div>
          <div className="col-md-6 projects-list">
            <p className="projects-tagline">{tagline}</p>
            <HomepageSingleProject className="medium" project={projects[2]}/>
          </div>
        </div>
      </div>
    </section>
);

export default HomepageProjects;
