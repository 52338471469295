import React from 'react';

/**
 * Homepage single project component
 */
const HomepageSingleProject = ({project, className}) => {
  if (project && project.id) {
    return (
        <a href={'/projets/' + project.uid} key={project.id} className={"preview-project " + className}>
          <img src={project.data.thumb.url} alt={project.data.thumb.alt}/>
          <h5 className="h5">{project.data.title[0].text}</h5>
        </a>
    );
  } return '';
};

export default HomepageSingleProject;
