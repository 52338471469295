// -- Prismic API endpoint
// Determines which repository to query and fetch data from
// Configure your site's access point here
export const apiEndpoint = 'https://remi-marandon.cdn.prismic.io/api/v2'

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
export const accessToken = 'MC5ZRFU2VnhFQUFDQUFNV1Rf.bu-_vSMbRXzvv70sNCwoYSt9Eu-_ve-_ve-_vRYUQzXvv719ezHvv73vv73vv73vv73vv73vv70'

// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (doc) => {
  if (doc.type === 'page') return `/${doc.uid}`
  return '/'
}
