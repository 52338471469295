import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Page not found (404) component
 */
const Offline = () => (
  <div className="not-found">
    <h1>Ooops</h1>
    <h2>Vous êtes hors ligne!</h2>
    <p>Il semble que vous ne soyez pas connecté, veuillez vérifier votre connexion et réessayer plus tard.</p>
      <a
          href="tel:+33650911157"
          className="btn btn-primary"
      ><img className="btn-picto" src="/images/telephone.svg" alt="picto téléphone"></img>
         Help !
      </a></div>
);

export default Offline;
