import React from 'react';

/**
 * SocialNetworks
 */
const SocialNetworks = () => (
    <div className="social-networks">
      {/*<a href="#fb">*/}
      {/*  <i className="picto picto-facebook"></i>*/}
      {/*</a>*/}
      <a href="https://www.malt.fr/profile/remimarandon" target="_blank" rel="noopener noreferrer">
        <i className="picto picto-malt"></i>
      </a>
      <a href="https://www.linkedin.com/in/remimarandon/" target="_blank" rel="noopener noreferrer">
        <i className="picto picto-linkedin"></i>
      </a>
    </div>
);

export default SocialNetworks;
