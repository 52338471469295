import './scss/custom.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(
    <App/>,
    document.getElementById('root'),
);

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js')
        .then(() => {
            console.log('Service Worker enregistré avec succès.');
        })
        .catch((error) => {
            console.error('Erreur lors de l\'enregistrement du Service Worker :', error);
        });

    let deferredPrompt;
    window.addEventListener('beforeinstallprompt', (e) => {
        // Empêche l'affichage immédiat de la boîte de dialogue par défaut
        e.preventDefault();
        // Sauvegarde l'événement pour l'utiliser plus tard
        deferredPrompt = e;

        setTimeout(function (){
            // Affiche une alerte ou un bouton pour inviter l'utilisateur à installer l'application
            const installButton = document.getElementById('install-button');

            if (installButton)
            installButton.addEventListener('click', () => {
                // Cache le bouton après le clic
                installButton.style.display = 'none';
                // Affiche la boîte de dialogue d'installation
                deferredPrompt.prompt();

                deferredPrompt.userChoice.then((choiceResult) => {
                    if (choiceResult.outcome === 'accepted') {
                        console.log('L\'utilisateur a accepté l\'installation');
                    } else {
                        console.log('L\'utilisateur a refusé l\'installation');
                    }
                    deferredPrompt = null;
                });
            });
        }, 300);

    });

    window.addEventListener('appinstalled', () => {
        console.log('L\'application a été installée avec succès');
    });

}
