import React, {useEffect, useState} from 'react';
import {DefaultLayout, SliceZone, SocialNetworks} from '../components';
import NotFound from './NotFound';
import Offline from "./Offline";
import {client} from '../utils/prismicHelpers';
import {RichTextField} from '../components/prismic-elements';
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

// import Swiper core and required modules
import SwiperCore, {
  Pagination, Navigation,
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

/**
 * Website page component
 */
const Project = ({match}) => {
  const [prismicData, setPrismicData] = useState({projectDoc: null, menuDoc: null});
  const [notFound, toggleNotFound] = useState(false);

  const uid = match.params.uid;

  // Get the page document from Prismic
  useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        const projectDoc = await client.getByUID('project', uid);
        const menuDoc = await client.getSingle('menu');

        if (projectDoc) {
          setPrismicData({projectDoc, menuDoc});
        } else {
          console.warn('Project was not found. Make sure it exists in your Prismic repository');
          toggleNotFound(true);
        }
      } catch (error) {
        console.error(error);
        toggleNotFound(true);
      }
    };
    fetchPrismicData();

    // Load new page at the top (when linking from the middle of another page)
    window.scrollTo(0, 0);
  }, [uid]);

  // Return the page if a document was retrieved from Prismic
  if (prismicData.projectDoc) {
    const projectDoc = prismicData.projectDoc;
    const menuDoc = prismicData.menuDoc;
    console.log(projectDoc);
    return (
        <DefaultLayout
            wrapperClass="project"
            menuDoc={menuDoc}
        >
          <section className="project-banner container-fluid" style={{backgroundImage: 'url(' + projectDoc.data.thumb.url + ')'}}>
            <div className="banner-overlay"></div>
            <div className="banner-content container">
              <div className="banner-content-half">
                {projectDoc.data.type.length ? <h2 className="h1 highlight">| {projectDoc.data.type[0].text}</h2> : null}
                <h1 className="h1">{projectDoc.data.title[0].text}</h1>
              </div>
            </div>
          </section>
          {projectDoc.data.type.length ?
              <section className="project-description container-fluid">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <RichTextField field={projectDoc.data.description}/>
                    </div>
                  </div>
                </div>
              </section>
              : null}
          {projectDoc.data.gallery.length ?

              <section className="project-gallery container-fluid">
                <div className="container">
                  <div className="row">
                    <div className="col">

                      <Swiper
                          spaceBetween={20}
                          slidesPerView={1}
                          loop={true}
                          navigation={true}
                          pagination={{'dynamicBullets': true}}
                          scrollbar={{draggable: true}}
                          onSwiper={() => {}}
                          onSlideChange={() => {}}
                      >
                        {projectDoc.data.gallery.map(imageData => (
                            <SwiperSlide>
                              <img src={imageData.picture.url} alt={imageData.picture.alt}/>
                              {imageData.caption.length ? <p className="image-caption">{imageData.caption[0].text}</p> : null}
                            </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                </div>
              </section>
              : null}

        </DefaultLayout>
    );
  } else if (notFound) {
    if (!navigator.onLine)
      return <Offline></Offline>
    return <NotFound/>;
  }
  return null;
};

export default Project;
