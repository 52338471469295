import React from 'react';
import {SocialNetworks} from '../index';

/**
 * Site footer component
 */
let year = new Date().getFullYear();
const Footer = () => (
    <footer>
      <img className="shape-4" src="/images/shape-indigo.svg" alt="élément décoratif"/>
      <div className="container">
        <h5 className="h5">Un projet ? Travaillons ensemble !</h5>
        <h3 className="h3">hello@remi-marandon.fr</h3>
        <a
            href="tel:+33650911157"
            className="btn btn-primary"
        ><img className="btn-picto" src="/images/telephone.svg" alt="picto téléphone"></img>
          06 50 91 11 57
        </a>
        <a
            href="/contact/"
            className="btn btn-secondary"
        ><img className="btn-picto" src="/images/send.svg" alt="picto enveloppe"></img>
          Formulaire de contact
        </a>
        <div className="copyright">&copy;&nbsp;{year} Rémi MARANDON.</div>
      </div>
      <SocialNetworks/>
      <a className="arrow-top" onClick={(e) => {
        e.preventDefault();
        window.scrollTo(0, 0);
        return false;
      }} href="#">
        <img src="/images/up.svg" alt="Go back to top"/>
      </a>
    </footer>
);

export default Footer;
