import React from 'react';

/**
 * Homepage skills component
 */
const HomepageSkills = ({data}) => {
  return (
      <section className="homepage-skills container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="h3">Expertises</h3>
            </div>
            {data.skills.map(skill => (
                <div className="col-md-4" key={skill.id}>
                  <div className="skill-preview">
                    <div>
                      <i className={'picto picto-' + skill.picto}/>
                      <h5 className="h5">{skill.title[0].text}</h5>
                    </div>
                    <p>{skill.description[0].text}</p>
                  </div>
                </div>
            ))}
          </div>
        </div>
      </section>
  );
};

export default HomepageSkills;
