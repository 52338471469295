import React from 'react';

/**
 * Homepage experiences component
 */
const HomepageExperiences = ({data}) => (
    <section className="homepage-experiences container-fluid">
      <div className="circle"></div>
        <div className="container">
          <div className="row">
            <div className="col-4 img-container">
              <img src={data.experience_img.url} alt={data.experience_img.alt} />
            </div>
            <div className="offset-lg-1 col-lg-7 experiences-container">
              <h5 className="h4 experiences-title">4 ans d'expérience en <span className="highlight">agence web</span> sur des <span className="highlight">projets à fort trafic</span></h5>
              {data.experience.map(experience => (
                  <div className="experience-preview">
                    <h5 className="h5">{experience.title[0].text}</h5>
                    <p>{experience.description[0].text}</p>
                  </div>
              ))}
            </div>
          </div>
        </div>
    </section>
);

export default HomepageExperiences;
