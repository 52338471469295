import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom';
import {HomePage, NotFound, Page, Preview, ContactForm, Project, ProjectList, Offline} from './pages';
import { apiEndpoint } from './prismic-configuration';
import {Scene} from "./components";

const App = () => {
  const repoNameArray = /([^/]+)\.cdn.prismic\.io\/api/.exec(apiEndpoint);
  const repoName = repoNameArray[1];

  return (
    <Fragment>
      <Helmet>
        <script async defer src={`//static.cdn.prismic.io/prismic.js?repo=${repoName}&new=true`} />
      </Helmet>
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={HomePage} />
          <Route exact path='/preview' component={Preview} />
          <Route exact path='/contact' component={ContactForm} />
          <Route exact path='/projets/:uid' component={Project} />
          <Route exact path='/projets' component={ProjectList} />
          <Route exact path='/threejs' component={Scene} />
          <Route exact path='/:uid' component={Page} />
          <Route exact path='/offline' component={Offline} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </Fragment>
  );
};

export default App;
