import React, {useEffect, useState} from 'react';
import {Footer, Header, MenuLink} from './';

/**
 * Default site layout component
 */
const DefaultLayout = ({wrapperClass, menuDoc, children}) => {

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // let mobileMenuButton = document.getElementsByClassName('top-menu__toggle-menu')[0];
    // let mobileMenu = document.getElementsByClassName('leftbar')[0];
    // let mobileMenuOverlay = document.getElementsByClassName('leftbar-overlay')[0];
    //
    // mobileMenuButton.addEventListener('click', () => {
    //   mobileMenu.classList.add('is-active');
    // });
    //
    // mobileMenuOverlay.addEventListener('click', () => {
    //   mobileMenu.classList.remove('is-active');
    // });
  }, []);

  return (
      <div id="outer-container">
        <div className={wrapperClass} id="main">
          {/*<div id="leftbar" className="leftbar">*/}
          {/*  <div className="leftbar__content">*/}
          {/*    <div className="leftbar__menu">*/}
          {/*      <button onClick={() => {document.getElementById('leftbar-overlay').click();}}><i className="picto picto-times close-menu"></i></button>*/}
          {/*      <ul>*/}
          {/*        <li className="nav-item">*/}
          {/*          <a className="nav-link contact" href="/">Accueil</a>*/}
          {/*        </li>*/}
          {/*        /!*{menuDoc.data.menu_links.map(menuLink => (*!/*/}
          {/*        /!*    <MenuLink menuLink={menuLink.link} key={menuLink.link.id}/>*!/*/}
          {/*        /!*))}*!/*/}
          {/*        <li className="nav-item">*/}
          {/*          <a className="nav-link contact" href="/contact/">Contact</a>*/}
          {/*        </li>*/}
          {/*      </ul>*/}
          {/*    </div>*/}
          {/*    <div className="leftbar__bottom">*/}
          {/*      <ul>*/}
          {/*        <li className="nav-item">*/}
          {/*          <a className="nav-link" href="/mentions-legales">Mentions légales</a>*/}
          {/*        </li>*/}
          {/*      </ul>*/}
          {/*    </div>*/}
          {/*  </div>*/}

          {/*</div>*/}
          {/*<div id="leftbar-overlay" className="leftbar-overlay"></div>*/}
          {/*<button className="d-block d-lg-none top-menu__toggle-menu" type="button"><i className="picto picto-burger" aria-hidden="true"></i></button>*/}
          <Header menuDoc={menuDoc}/>
          {children}
          <Footer/>
        </div>
      </div>
  );
};

export default DefaultLayout;
