import React from 'react';
import {DefaultLayout, SocialNetworks} from './index';

/**
 * Homepage banner component
 */
const HomepageBanner = ({tagline}) => (
    <section className="homepage-banner container-fluid">
      <div className="banner-content container">
        <div className="banner-content-half">
          <h1 className="h1 highlight">| Rémi Marandon</h1>
          <h2 className="h1">
            Développeur Indépendant
          </h2>
          <p className="mb-5">
            {tagline}
          </p>
          <a
              href="/contact/"
              className="btn btn-secondary"
          >
            Un projet ? Discutons-en
          </a>
          <a
              href="tel:+33650911157"
              className="btn btn-primary"
          >
            06 50 91 11 57
          </a>
        </div>
      </div>
      <SocialNetworks/>
    </section>
);

export default HomepageBanner;
