import * as THREE from 'three';
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader";
// import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

export default class ViewGL {
    constructor(canvasRef) {
        this.fpsLimit = 75
        // Init
        this.init(canvasRef);
        // Update (Loop)
        this.update();
    }

    // ******************* PUBLIC EVENTS ******************* //
    updateValue(value) {
        // Whatever you need to do with React props
    }

    onMouseMove() {
        // Mouse moves
    }

    onWindowResize(vpW, vpH) {
        // Renderer
        this.renderer.setSize(vpW, vpH);
        // Update camera
        this.camera.aspect = vpW / vpH;
        this.camera.updateProjectionMatrix();
    }

    // ******************* RENDER LOOP ******************* //
    update(now) {

        // FPS handling
        const elapsedTime = this.clock.getElapsedTime()
        const deltaTime = elapsedTime - this.previousTime
        this.previousTime = elapsedTime

        this.renderer.render(this.scene, this.camera);
        // Update controls
        // this.controls.update()
        if (this.duck)
            this.duck.rotation.y -= 0.01

        // Animating objects
        if (this.mixer)
            this.mixer.update(deltaTime)

        // Limiting FPS and CPU consuming
        setTimeout(() => {
            requestAnimationFrame(this.update.bind(this));
        }, 1000 / this.fpsLimit);
    }

    // ****** Init ThreeJS Scene ****** //

    init(canvasRef) {

        // Init scene and renderer
        this.scene = new THREE.Scene();
        this.renderer = new THREE.WebGLRenderer({
            canvas: canvasRef,
            antialias: false,
            alpha: true
        });
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.setPixelRatio(1);
        this.renderer.setClearColor(0xffffff, 0);

        // Add a camera
        this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 100);
        this.camera.position.x = 0;
        this.camera.position.y = 2;
        this.camera.position.z = 5;

        this.clock = new THREE.Clock()
        this.previousTime = 0

        // this.controls = new OrbitControls(this.camera, canvasRef)
        // this.controls.target.set(0, 0.75, 0)
        // this.controls.enableDamping = true
        /**
         * Floor
         */
        // const floor = new THREE.Mesh(
        //     new THREE.PlaneGeometry(10, 10),
        //     new THREE.MeshStandardMaterial({
        //         color: '#444444',
        //         metalness: 0,
        //         roughness: 0.5
        //     })
        // )
        // floor.receiveShadow = true
        // floor.rotation.x = -Math.PI * 0.5
        // this.scene.add(floor)

        /**
         * Lights
         */
        const ambientLight = new THREE.AmbientLight(0xffffff, 1.2)
        this.scene.add(ambientLight)

        // const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6)
        // directionalLight.castShadow = true
        // directionalLight.shadow.mapSize.set(1024, 1024)
        // directionalLight.shadow.camera.far = 15
        // directionalLight.shadow.camera.left = -7
        // directionalLight.shadow.camera.top = 7
        // directionalLight.shadow.camera.right = 7
        // directionalLight.shadow.camera.bottom = -7
        // directionalLight.position.set(-5, 5, 0)
        // this.scene.add(directionalLight)

        // Init a gltfLoader
        this.dracoLoader = new DRACOLoader()
        this.dracoLoader.setDecoderPath('/draco/')

        this.loader = new GLTFLoader()
        this.loader.setDRACOLoader(this.dracoLoader)

        this.loader.load(
            './models/Duck/glTF/Duck.gltf',
            (gltf) => {
                console.log('success');
                this.duck = gltf.scene.children[0].children[1];
                this.duck.position.x = (window.innerWidth / 2)
                this.duck.position.z = -1500
                this.scene.add(gltf.scene.children[0])
            },
            () => {
                console.log('progress');
            }, (err) => {
                console.log('error');
                console.log(err);
            },
        )

        //
        this.loader.load(
            '/models/Fox/glTF/Fox.gltf',
            (gltf) => {
                console.log('success');

                // For animations
                this.mixer = new THREE.AnimationMixer(gltf.scene)
                const action = this.mixer.clipAction(gltf.animations[2])

                action.play()
                // Adding fox object in scene
                gltf.scene.scale.set(0.025, 0.025, 0.025);
                gltf.scene.rotation.y -= 5
                gltf.scene.position.z -= 5
                gltf.scene.position.y -= 5
                gltf.scene.position.x -= 5
                this.scene.add(gltf.scene)

            },
            () => {
                console.log('progress');
            }, () => {
                console.log('error');
            },
        )

        // this.loader.load(
        //     '/models/Raptor/glTF-Embedded/Raptor.gltf',
        //     (gltf) => {
        //         console.log('success');
        //
        //         // For animations
        //         // this.mixer = new THREE.AnimationMixer(gltf.scene)
        //         // const action = this.mixer.clipAction(gltf.animations[2])
        //         //
        //         // action.play()
        //         // // Adding object in scene
        //
        //         console.log(gltf);
        //         gltf.scene.rotation.y += 5
        //         gltf.scene.position.z -= 5
        //         gltf.scene.position.y += 5
        //         gltf.scene.position.x += 5
        //         this.scene.add(gltf.scene)
        //
        //     },
        //     () => {
        //         console.log('progress');
        //     }, (err) => {
        //         console.log(err);
        //         console.log('error');
        //     },
        // )

        // this.loader.load(
        //     '/models/Motorcycle/glTF-Embedded/Motorcycle.gltf',
        //     (gltf) => {
        //         console.log('success');
        //
        //         // For animations
        //         // this.mixer = new THREE.AnimationMixer(gltf.scene)
        //         // const action = this.mixer.clipAction(gltf.animations[2])
        //         //
        //         // action.play()
        //         // // Adding object in scene
        //         gltf.scene.scale.set(0.25, 0.25, 0.25);
        //         // gltf.scene.rotation.y += 1
        //         // gltf.scene.position.z -= 2
        //         // gltf.scene.position.y = 0
        //         gltf.scene.position.x += 5
        //         this.scene.add(gltf.scene)
        //
        //     },
        //     () => {
        //         console.log('progress');
        //     }, (err) => {
        //         console.log(err);
        //         console.log('error');
        //     },
        // )

    }
}