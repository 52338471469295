import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Page not found (404) component
 */
const NotFound = () => (
  <div className="not-found">
    <h1>404</h1>
    <h2>Page non trouvée</h2>
    <p>Désolé mais cette page n'existe pas, il s'agit probablement d'une erreur :)</p>
    <Link className="btn btn-primary" to="/">Retour à l'accueil</Link>
  </div>
);

export default NotFound;
